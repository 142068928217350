/* You can add global styles to this file, and also import other style files */

$primary: #4dcfff;

$theme-colors: (
	primary: $primary,
	success: #5cb85c,
	info: #5bc0de,
	warning: #f0ad4e,
	danger: #d9534f,
);

$btn-border-radius: 2em;

@import 'bootstrap/scss/bootstrap';

.btn-primary {
	@include button-variant($primary, $primary, #fff);
}


@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-fresh.css";
@import 'viewerjs/dist/viewer.css';

html,
body {
	height: 100%;
}
body {
	margin: 0;
	background-color: transparent;
}

app-root {
	font-family: "futura-pt", sans-serif;

	app-version-select {
		mat-chip.mod-no-line-height.mat-chip {
			line-height: 0;
		}
	}
}

.result-img {
	width: 22em;
	height: 15em;
	border: 3px solid black;
	background-color: black;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;

	&::before {
		content: "";
		display: block;
		height : 0;
		width : 0;
		position: absolute;
	}
}

.is-after .result-img::before {
	border-top : 12px solid transparent;
	border-right : 12px solid black;
	border-bottom : 12px solid transparent;
	top: calc(50% - 12px);
	left: -15px;
}

.is-above .result-img::before {
	border-top : 12px solid black;
	border-right : 12px solid transparent;
	border-left : 12px solid transparent;
	left: calc(50% - 12px);
	bottom: -15px;
}

.proposal-dialog-panel {
	overflow: auto;
	max-height: 100%;
	width: 80vw;

	@media screen and (max-width: 900px) {
		width: 95vw;
	}

	.mat-dialog-container {
		padding: 10px;
	}
}

.mat-card-header-text {
	width: 100%;
}

button + button {
	margin-left: 5px !important;
}
